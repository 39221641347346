import dayjs from 'dayjs';
import { configure } from 'mobx';
import relativeTime from 'dayjs/plugin/relativeTime';
import { env } from 'env';
import { localStorageStores } from 'state/localStorageStores';
import { initAmplitude } from './amplitude';
import { log } from './log';
import { refreshUserContent } from './fetchUserContent';
import { userPropertiesListener } from './userPropertiesListener';
import { initPaddle } from './paddle';
import { initHotjar, startHotjarRecording } from './hotjar';
import { sendEvent } from './analyticsEvents';
import { HevyWebsocketClient } from './globals/HevyWebsocketClient';
import API from './API';
import { fireAndForget } from './async';
import { initAppleLogin } from './appleLogin';
import { maintenenceModeListener } from './maintenenceModeListener';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';
import { initAPInterceptors } from './APInterceptors';
import { PushNotifications } from './globals/pushNotifications';
import { modal } from 'components/Modals/ModalManager';
import { ServerStorage } from 'state/ServerStorage';
import { captureException } from '@sentry/nextjs';
import { logout } from './logout';

dayjs.extend(relativeTime);

configure({
  enforceActions: 'never',
});

const hevyEmployeeUsernames = [
  'ryan',
  'hevy_coach',
  'desmond',
  'guillemros',
  'gymrat',
  'hevyapp',
  'testbloop1',
  'testbloop2',
  'onboarding',
  'gui',
  'seasidecode',
  'aabbccddcc',
  'batu',
  'ryno2019',
  'ryantest',
];

const initializeServices = () => {
  initAPInterceptors();
  initPaddle();
  initAppleLogin();
  initHotjar();
  initAmplitude();
  userPropertiesListener();
  maintenenceModeListener();
};

export const bootstrap = async () => {
  log('🥾 bootstrapping');
  log(`🔗 api: `, env.apiUrl);

  let path: string | undefined = undefined;
  try {
    path = window.location.pathname.split('/')[1];
  } catch (e) {
    captureException(`[bootstrap] - Error reading path: ${e}`);
  }

  if (!localStorageStores.auth.isAuthenticated) {
    localStorageStores.lifecycle.setBootstrapComplete();
    initializeServices();
    if (!!path) {
      sendEvent('appLaunch', { authenticated: localStorageStores.auth.isAuthenticated, path });
    }
    startHotjarRecording();
    return;
  }

  log('🥾 hydrating disk-stored data');
  try {
    localStorageStores.hydrateAll();
  } catch (e) {
    captureException(`[bootstrap] - Error hydrating stores: ${e}`);
    logout('bootstrapError');
    return;
  }

  try {
    PushNotifications.initialize();
  } catch (e) {
    captureException(`[bootstrap] - Error initializing push notifications: ${e}`);
  }

  HevyWebsocketClient.startTryingToConnect();
  try {
    initializeServices();
  } catch (e) {
    captureException(`[bootstrap] - Error initializing services: ${e}`);
  }

  await refreshUserContent().catch(e => {
    captureException(`[bootstrap] - Error fetching required user content: ${e}`);
    logout('bootstrapError');
    return;
  });

  PlanAggregator.handleInvalidPlanIfNeeded();

  localStorageStores.lifecycle.setBootstrapComplete();
  if (
    (localStorageStores.account.username ?? '' === '') ||
    !hevyEmployeeUsernames.includes(localStorageStores.account.username)
  ) {
    startHotjarRecording();
  }
  if (!!path) {
    sendEvent('appLaunch', { authenticated: localStorageStores.auth.isAuthenticated, path });
  }
  fireAndForget([API.postAuthenticatedBootstrapComplete()]);

  const hasSeenModalKey = 'COACH_WEB_USER_HAS_VIEWED_MOBILE_APP_LAUNCH_MODAL';

  if (
    ServerStorage.get(hasSeenModalKey) !== true &&
    dayjs(localStorageStores.account.created_at).isBefore(dayjs('2024-10-10'))
  ) {
    fireAndForget([ServerStorage.set(hasSeenModalKey, true)]);
    modal.openHevyCoachAppModal();
  }
};
